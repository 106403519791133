<template>
  <el-main>
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="200px" class="demo-ruleForm">
      <el-form-item>
        <template #label><div class="title">佣金收益设置</div></template>
      </el-form-item>
      <el-form-item label="推广员奖励比例：" prop="distribution_award_ratio">
        <template>
          <div class="content">
            <el-input v-model="ruleForm.distribution_award_ratio"></el-input>
            <div class="tips">%， 占用户显示订单金额的比例</div>
          </div>
        </template>
      </el-form-item>
      <el-form-item label="区域管理员奖励比例：" prop="district_award_ratio">
        <template>
          <div class="content">
            <el-input v-model="ruleForm.district_award_ratio"></el-input>
            <div class="tips">%， 占订单利润比例(利润=回收站支付金额-用户订单金额-推广员奖励金额)</div>
          </div>
        </template>
      </el-form-item>
      <el-form-item label="回收员(专职)奖励比例：" prop="full_time_award_ratio">
        <template>
          <div class="content">
            <el-input v-model="ruleForm.full_time_award_ratio"></el-input>
            <div class="tips">%， 占订单利润比例(利润=回收站支付金额-用户订单金额-推广员奖励金额)</div>
          </div>
        </template>
      </el-form-item>
      <el-form-item label="回收员(兼职)奖励比例：" prop="on_the_side_award_ratio">
        <template>
          <div class="content">
            <el-input v-model="ruleForm.on_the_side_award_ratio"></el-input>
            <div class="tips">%， 占订单利润比例(利润=回收站支付金额-用户订单金额-推广员奖励金额)</div>
          </div>
        </template>
      </el-form-item>
      <el-form-item label="能量值设置：" prop="energy_value_setting">
        <template>
          <div class="content">
            <div class="tipsR">每一元订单，可产生收益值</div>
            <el-input class="w312" v-model="ruleForm.energy_value_setting"></el-input>
          </div>
        </template>
      </el-form-item>
      <el-form-item label="钱包金额超过：" prop="min_withdrawal_amount">
        <template>
          <div class="content">
            <el-input v-model="ruleForm.min_withdrawal_amount"></el-input>
            <div class="tips">元，用户才可以提现</div>
          </div>
        </template>
      </el-form-item>
      <el-form-item>
        <template #label><div class="title">订单设置</div></template>
      </el-form-item>
      <el-form-item label="订单超过：" prop="timeout_cancel_order">
        <template>
          <div class="content">
            <el-input v-model="ruleForm.timeout_cancel_order">
              <template slot="append">小时</template>
            </el-input>
            <div class="tips">无人接单，自动取消订单</div>
          </div>
        </template>
      </el-form-item>
      <el-form-item label="预约日期：" prop="appointment_date">
        <template>
          <div class="content">
            <el-checkbox v-model="appointmentDataIsAll" @change="allHandleChange">全选</el-checkbox>
            <el-checkbox-group v-model="ruleForm.appointment_date" @change="handleCheckedChange">
              <el-checkbox v-for="item in data" :label="item.name" :key="item.id"></el-checkbox>
            </el-checkbox-group>
          </div>
        </template>
      </el-form-item>
      <el-form-item label="预约时段：" prop="appointment_time">
        <template>
          <div class="contentCloum">
            <div class="row" v-for="(item, index) in ruleForm.appointment_time" :key="index">
              <el-time-picker class="mr30" v-model="item[0]" placeholder="开始时间" format="HH:mm:ss" @change="startTimeChange(index)"></el-time-picker>
              <el-time-picker class="mr30" v-model="item[1]" :picker-options="{ selectableRange: [`${dealTime(item[0])}-23:59:59`], format: 'HH:mm:ss' }" placeholder="结束时间">></el-time-picker>
              <i class="el-icon-circle-close closeIcon" v-if="index !== 0" @click="delAppointmentTime(index)"></i>
            </div>
            <el-button type="text" @click="addPushAppointmentTime">+添加预约时段</el-button>
          </div>
        </template>
      </el-form-item>
    </el-form>
    <Preservation @preservation="preservation"></Preservation>
  </el-main>
</template>

<script>
import Preservation from '@/components/preservation';
import _ from 'lodash';
export default {
  components: {
    Preservation,
  },
  data() {
    function check_appointment_date(rule, value, callback) {
      if (value.length === 0) {
        callback(new Error('请勾选预约日期'));
      } else {
        callback();
      }
    }
    function check_appointment_time(rule, value, callback) {
      if (value[0].length === 0) {
        callback(new Error('请选择预约时段'));
      } else {
        for (let i = 0; i < value.length; i++) {
          if (!value[i][0] || !value[i][1]) {
            callback(new Error('请检查预约时段是否填写完整'));
            break;
          } else {
            if (i === value.length - 1) {
              callback();
            }
          }
        }
      }
    }
    return {
      ruleForm: {
        distribution_award_ratio: '',
        district_award_ratio: '',
        full_time_award_ratio: '',
        on_the_side_award_ratio: '',
        energy_value_setting: '',
        min_withdrawal_amount: '',
        timeout_cancel_order: '',
        appointment_date: [],
        appointment_time: [[]],
      },
      rules: {
        distribution_award_ratio: [{ required: true, message: '请输入推广员奖励比例', trigger: 'blur' }],
        district_award_ratio: [{ required: true, message: '请输入区域管理员奖励比例', trigger: 'blur' }],
        full_time_award_ratio: [{ required: true, message: '请输入回收员(专职)奖励比例', trigger: 'blur' }],
        on_the_side_award_ratio: [{ required: true, message: '请输入回收员(兼职)奖励比例', trigger: 'blur' }],
        energy_value_setting: [{ required: true, message: '请输入能量值设置', trigger: 'blur' }],
        min_withdrawal_amount: [{ required: true, message: '请输入钱包金额超过', trigger: 'blur' }],
        timeout_cancel_order: [{ required: true, message: '请输入自动取消订单时间', trigger: 'blur' }],
        appointment_date: [{ validator: check_appointment_date, trigger: 'change' }],
        appointment_time: [{ validator: check_appointment_time, trigger: 'change' }],
      },
      appointmentDataIsAll: false,
      data: [
        { name: '星期一', id: '1' },
        { name: '星期二', id: '2' },
        { name: '星期三', id: '3' },
        { name: '星期四', id: '4' },
        { name: '星期五', id: '5' },
        { name: '星期六', id: '6' },
        { name: '星期日', id: '7' },
      ],
    };
  },
  created() {
    this.getInfo();
  },
  methods: {
    getInfo() {
      this.$axios.post(this.$api.O2O.basics.getBasicsSet).then(res => {
        if (res.code === 0) {
          if (res.result) {
            this.ruleForm = res.result;
            this.ruleForm.appointment_date = this.getDataInfo(this.ruleForm.appointment_dates);
            this.ruleForm.appointment_time = [];
            this.ruleForm.appointment_times.map(item => {
              let date = [new Date(Number(item[0]) * 1000), new Date(Number(item[1]) * 1000)];
              this.ruleForm.appointment_time.push(date);
            });
            if (this.ruleForm.appointment_date.length === 7) {
              this.appointmentDataIsAll = true;
            }
            delete this.ruleForm.appointment_dates;
            delete this.ruleForm.appointment_times;
            delete this.ruleForm.id;
            delete this.ruleForm.register_agreement;
            delete this.ruleForm.uniacid;
            delete this.ruleForm.user_service_agreement;
            delete this.ruleForm.visit_service_agreement;
          }
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    getDataInfo(arr) {
      let result = [];
      let condition = isNaN(Number(arr[0])) ? 'name' : 'id';
      let need = isNaN(Number(arr[0])) ? 'id' : 'name';
      arr.map(item => {
        let res = this.data.find(item1 => item1[condition] === item)[need];
        result.push(res);
      });
      return result;
    },

    // 选择第一个时间时 处理第二个时间的最小值
    dealTime(time) {
      if (!time) return;
      let h = time.getHours();
      let m = time.getMinutes();
      let s = time.getSeconds();
      return `${h}:${m}:${s}`;
    },
    // 第一个时间发生改变时
    startTimeChange(index) {
      let nowTime = this.ruleForm.appointment_time[index];
      console.log(nowTime[0]);
      if (!nowTime[0] || nowTime.length < 2 || !nowTime[1]) return;
      if (nowTime[0].getTime() >= nowTime[1].getTime()) {
        nowTime.splice(1, 1);
      }
    },
    // 全选状态发生改变时
    allHandleChange() {
      this.ruleForm.appointment_date = this.appointmentDataIsAll ? ['星期一', '星期二', '星期三', '星期四', '星期五', '星期六', '星期日'] : [];
    },
    // 选择星期几时
    handleCheckedChange(value) {
      this.appointmentDataIsAll = this.ruleForm.appointment_date.length === this.data.length;
    },
    // 添加预约时段
    addPushAppointmentTime() {
      this.ruleForm.appointment_time.push([]);
    },
    // 删除预约时段
    delAppointmentTime(index) {
      this.ruleForm.appointment_time.splice(index, 1);
    },
    // 保存配置
    preservation() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          let obj = _.cloneDeep(this.ruleForm);
          delete obj.appointment_date;
          delete obj.appointment_time;
          obj.appointment_date = this.getDataInfo(this.ruleForm.appointment_date);
          obj.appointment_time = [];
          this.ruleForm.appointment_time.map(item => {
            if (item.length === 2 && item[0] && item[1]) {
              let data = [Math.floor(item[0].getTime() / 1000), Math.floor(item[1].getTime() / 1000)];
              obj.appointment_time.push(data);
            }
          });
          this.$axios.post(this.$api.O2O.basics.editBasicsSet, obj).then(res => {
            if (res.code === 0) {
              this.$message.success('保存配置成功');
            } else {
              this.$message.error(res.msg);
            }
          });
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.el-main {
  background-color: #fff;
  min-height: 100%;
  padding-bottom: 60px;
  .title {
    line-height: 45px;
    font-weight: bold;
    text-align: left;
  }
  .content {
    display: flex;
    // align-items: center;
    align-items: flex-start;
    .el-input {
      width: 500px;
    }
    .el-checkbox {
      margin-right: 30px;
      line-height: 45px !important;
    }

    .w312 {
      width: 312px !important;
    }
    .tips {
      color: #cbcbcb;
      font-size: 14px;
      margin-left: 20px;
    }
    .tipsR {
      color: #cbcbcb;
      font-size: 14px;
      margin-right: 20px;
    }
  }
  .contentCloum {
    .row {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      .mr30 {
        margin-right: 40px;
      }
      .closeIcon {
        font-size: 24px;
      }
    }
  }
}
</style>
